import './_news.scss';

const news = function() {

};

document.addEventListener('DOMContentLoaded', function(){
    news()
}, false);

window.addEventListener('acf/loaded/news', function (e) {
    news()
});